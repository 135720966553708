import { useEffect, useState, useRef } from 'react';

import Ajax from '@utils/ajax';

// eslint-disable-next-line no-restricted-imports
import StaticHtml from 'static-html.react';
// Modal has script tags that need to be evaluated

import { bem } from '../../../utils';

import AppModal from './modal';
import { Flex } from '@bamboohr/fabric';

export default function ConfigModal(props) {
	const {
		app = {},
		isOpen,
		actions: { saveConfig },
	} = props;
	const [content, setContent] = useState(null);
	const [canInstall, setCanInstall] = useState(app.canCompleteInstall);
	const [isLoading, setIsLoading] = useState(true);
	const formRef = useRef(null);

	useEffect(() => {
		if (!isOpen) {
			if (content) {
				setContent(null);
			}
			setIsLoading(true);

			return;
		}

		Ajax.get(app.installUrl, {
			SettingsApps: true,
		}).then(({ data }) => {
			setContent(data.modalHtml !== undefined && data.modalHtml !== null ? data.modalHtml : data);
			setCanInstall(data.canCompleteInstall !== undefined && data.canCompleteInstall !== null ? data.canCompleteInstall : true);
			setIsLoading(false);
		});
	}, [isOpen]);

	const primaryAction = () => {
		const siteNavItem = document.querySelector('.js-siteManageNavPasswordItem');

		/**
		 * Add callback to hide "Change Password" option from Site Nav dropdown
		 * if installing SSO apps with allowPasswordLogin checkbox unchecked.
		 */
		let callback = null;
		if (
			(app.name === 'googleSso' ||
				app.name === 'microsoftSso' ||
				app.name === 'saml' ||
				app.name === 'okta' ||
				app.name === 'onelogin') &&
			!app.installed &&
			siteNavItem
		) {
			const allowPasswordElement = document.getElementById('allowPasswordLogin');
			const allowPasswordLogin = allowPasswordElement !== null ? allowPasswordElement.checked : false;

			if (!allowPasswordLogin) {
				callback = () => {
					window.SESSION_COMPANY.allowPasswordLogin = false;
					siteNavItem.classList.add('hidden');
				};
			}
		}
        if (app.name === 'gtClocks') {
            callback = () => {
                window.location.replace(app.redirectUrl);
            }
        }

		return saveConfig(app, formRef.current, callback);
	};

	return (
		<AppModal
			{...props}
			headerType='text'
			isLoading={isLoading}
			isProcessing={!content}
			primaryAction={
				canInstall
					? () => {
							const isValid = validate(formRef.current);

							return isValid ? primaryAction() : Promise.reject($.__('Woah! Please fix any errors and try again.'));
					  }
					: null
			}
			primaryActionText={app.installed ? $.__('Save') : $.__('Install')}
			title={$.__('%1$s Settings', app.title)}
			type='config'
			url={app.installUrl}
		>
			<Flex justifyContent='center' mb={3}>
				<img alt={app.title} className={bem('appModalLogo', [app.name, 'config'])} src={app.publisher && app.publisher.logo} />
			</Flex>
			{content && (
				<form ref={formRef} className={bem('appModalForm', ['^BhrForms', app.name, 'config'])}>
					<input name='action' type='hidden' value='Save' />
					<StaticHtml dangerous={true} html={content} />
				</form>
			)}
		</AppModal>
	);
}

/**
 * @param {HTMLFormElement} form
 */
function validate(form) {
	const $form = $(form);

	if (!$form.data('validator')) {
		$form.bhrValidate();
	}

	if ($form.valid()) {
		window.closeMessage();
		return true;
	}

	return false;
}
